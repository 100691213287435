<template>
  <header>
      <div id="iconWrapper">
    <router-link to="/">
      <img src="../../../public/img/icons/icon.png" alt="NG Accounting" id="icon" />
    </router-link>
      </div>
      <burger />
    <!-- <div id="hamNav">
      <Slide @openMenu="this.handleOpenMenu">
        <router-link tag="li" to="/">
          <span>Home</span>
        </router-link>
        <router-link tag="li" to="/about">
          <span>About</span>
        </router-link>
        <router-link tag="li" to="/Contact">
          <span>Contact</span>
        </router-link>
      </Slide>
    </div> -->
    <!--Calendar-->
    <!-- <div class="some-class">
      <div id="calendar" class="dycalendar-container"></div>
    </div> -->
    <div id="menu2">
      <ul>
        <router-link tag="li" to="/">Home</router-link>
        <router-link tag="li" to="about">About</router-link>
        <router-link tag="li" to="contact">Contact</router-link>
      </ul>
    </div>
  </header>
</template>

<script>
import burger from '../../controls/BurgerMenu.vue'
export default {
  components: {
    burger
  }
}
</script>

<style>
</style>
