import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import VueAgile from 'vue-agile'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import { VueCookieNext } from 'vue-cookie-next';
// import VueMeta from 'vue-3-meta';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueAgile)
app.use(VueCookieNext)
// app.use(VueMeta, {refreshOnceOnNavigation: true})
app.use(moment)
app.config.productionTip = false
app.config.globalProperties.$filters = {
    formatDay(value) {
      return moment(String(value)).format('dddd')
    },
    formatDate(value) {
        return moment(String(value)).format('Do')
      },
      formatMonth(value) {
        return moment(String(value)).format('MMMM YYYY')
      }
  };
app.mount('#app')
