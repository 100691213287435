<template>
  <div>
    <section class="info">
      <h3>Business information</h3>
      <section id="half">
        <section class="col col2">
          <ul>
            <li>PO Box 1601</li>
            <li>Mead, WA 99021</li>
            <li>Fax: (509) 351-2758</li>
          </ul>
          <ul>
            <router-link tag="a" to="/privacy">Privacy Policy</router-link>
          </ul>
        </section>
        <section class="col col2">
          <ul>
            <li>
              <a href="sms:15099791378">Send a Text</a>
            </li>
            <li>
              <a href="tel:15099791378">(509) 979-1378</a>
            </li>
            <li>
              <a href="mailto:Nadenelewisgreen@yahoo.com">Nadenelewisgreen@yahoo.com</a>
            </li>
          </ul>
        </section>
      </section>
    </section>
    <div id="footer">
    <h4>
    <a href="https://www.ng-accounting.com">NG Accounting&copy;{{ new Date().getFullYear() }}</a></h4>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>

</style>
