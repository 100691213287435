import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/Privacy.vue'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else
    if (to.hash) {
      return { selector: to.hash }
    }
    return { left: 0, top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: 'NG Accounting | Home'
      }
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        title: 'NG Accounting | About'
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta: {
        title: 'NG Accounting | Contact'
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        title: 'NG Accounting| Privacy'
      }
    }
]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})
export default router
