<template>
  <div class="home">
    <div class="home-top">
      <slider />
    </div>

    <div class="home-bottom">
      <section id="back-wrapper">
        <h1>NG Accounting</h1>
        <h4>Professional Accounting Services</h4>
        <div id="back">
          <div id="welcome">
            <article>
              <p>
                Nadene Lewis-Green is the owner of NG Accounting, PS. People
                desire to succeed in business and in their personal finances,
                and on the road to this success many people think that
                accounting is just about paying taxes when they are due.
                However, accounting is actually the road that will help lead
                your business to success. Get to know me as your accountant,
                <em
                  >I work to meet your specific accounting needs to help you
                  stay focused on the more important things in your
                  business.</em
                >
                Please explore NG Accounting services and feel free to get to
                know why you want to build your business with us in our
                <router-link tag="a" to="about">About</router-link> section.
              </p>
            </article>
          </div>
          <div class="tooltip">
            <span class="tooltiptext">This image is copyrighted by <router-link tag="a" to="/privacy">Caili Hartmen Photography&copy;</router-link></span>
            <img src="../../public/img/nadeneGreen1.jpg" alt="Nadene Green" />
          </div>
        </div>
      </section>

      <section id="services">
        <h2>Services Offered</h2>
        <article>
          <section class="col col3">
            <ul>
              <div id="infoHeading">
                <li>Monthly Accounting:</li>
              </div>
              <li>Accounts Receivable</li>
              <li>Accounts Payable</li>
              <li>Payroll</li>
              <li>Financial Statements</li>
              <li>Reconciliation of Accounts</li>
              <li>Organize Paperwork</li>
              <li>
                Sales Tax Preparation, filing & paying for Washington & Idaho
                State
              </li>
            </ul>
          </section>
          <section class="col col3">
            <ul>
              <div id="infoHeading">
                <li>Payroll Services:</li>
              </div>
              <li>Weekly, Bi-Weekly, Semi-Monthly, Monthly</li>
              <li>
                Preparation, filing &amp; submitting payment Payroll Tax Reports
                for all agencies including, but not limited, to Washington and
                Idaho State
              </li>
              <section id="sub">
                <ul>
                  <li>
                    Form 941, Form 940, Department of L&amp;I, Employment
                    Security
                  </li>
                  <li>W-2 Forms</li>
                  <li>1099 Forms</li>
                </ul>
              </section>
            </ul>
          </section>
          <section class="col col3">
            <ul>
              <div id="infoHeading">
                <li>Tax Returns:</li>
              </div>
              <li>Personal - 1040, 1040A, 1040EZ</li>
              <li>Small Business/Rental Property Returns</li>
              <li>Partnership Returns</li>
              <li>Corporation and S-Corporation Returns</li>
            </ul>
          </section>
          <h5>
            <em>Price is subject to workload</em> -
            <b>Free Consultations</b>
          </h5>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import Slider from "../components/Slider.vue";
export default {
  components: {
    Slider,
  },
};
</script>

<style lang="css">
.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-top {
  height: auto;
}
.home-bottom {
  position: relative;
}
#back-wrapper {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(../../public/img/icons/back.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

#back h4 {
  position: relative;
  padding-bottom: 55px;
}
#back h5 {
  position: relative;
}

#back > div:last-child > img {
  max-width: 70%;
  height: 100%;
}

#welcome {
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  background-blend-mode: soft-light;
  opacity: 0.8;
  box-shadow: 3px 3px 4px #f2f2f2;
  display: flex;
  align-items: center;
}
#back {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 868px) {
  #back {
    display: block;
  }
}

@media screen and (max-width: 1180px){
  #back > div:last-child > img {
  max-width: 100%;
  width: 100%;
  /* height: 100%; */
}
}
</style>
