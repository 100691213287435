<template>
  <div id="contactWrapper">
    <section id="contactTop">
      <h1>Contact Us</h1>
      <form @submit.prevent="onSubmit">
        <section id="contact">
          <div class="group">
            <input
              required
              name="name"
              v-model="name"
              type="text"
              autocomplete="off"
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Name</label>
          </div>

          <div class="group">
            <input
              required
              name="email"
              v-model="email"
              type="email"
              autocomplete="off"
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Email</label>
          </div>
        </section>
        <div class="group">
          <textarea name="comment" v-model="message"></textarea>
          <span class="highlight"></span>
          <span class="bar"></span>
          <label>Send us a message!</label>
        </div>
        <div class="flex">
          <p>I have agreed to this websites <router-link tag="a" to="privacy">data policy</router-link></p>
          <input type="checkbox" name="checkbox" class="checkbox" required />
</div>
        <button>Submit</button>
      </form>
    </section>
  </div>
</template>
<script>
import errorHandler from "../config/error-handler";
import { mapActions } from "vuex";
export default {
  data: () => ({
    name: "",
    email: "",
    message: "",
    loading: false,
    errors: [],
  }),
  methods: {
    ...mapActions(["submit"]),
    async onSubmit() {
      try {
        const payload = {
          name: this.name,
          email: this.email,
          message: this.message,
        };
        const req = await this.submit(payload);
        this.$router.push({ name: "Home" });
        return req;
      } catch (error) {
        this.loading = false;
        this.errors = [];
        setTimeout(() => {
          this.errors = [];
        }, 3000);
        return await errorHandler.handleError(error, this.errors);
      }
    },
  },
};
</script>

<style style="css" scoped>
#contactWrapper {
  position: relative;
  background-image: url(../../public/img/icons/IMG4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin: 0 auto;
  display: block;
  min-height: calc(100vh - 50px);
}

#contactTop {
  position: relative;
  padding-top: 100px;
  width: 50%;
  margin: 0 auto;
}

.checkbox {
  position: relative;
  margin: 0;
  left: 5px;
  width: 20px;
  top: 10px;
  color: #fff;
}
.flex {
  display: flex;
  justify-content: flex-start;
}

.flex > p {
  color: #1f2946;
}
* {
  box-sizing: border-box;
}

h2 {
  text-align: center;
  margin-bottom: 50px;
}
h2 small {
  font-weight: normal;
  color: #888;
  display: block;
}
.footer {
  text-align: center;
}
.footer a {
  color: #53b2c8;
}

.group {
  position: relative;
  margin-bottom: 45px;
}
input,
textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}

label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label,
textarea:focus ~ label,
textarea:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #5264ae;
}

input:required {
  box-shadow: none;
}

.bar {
  position: relative;
  display: block;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

input:focus ~ .bar:before,
input:focus ~ .bar:after,
textarea:focus ~ .bar:before,
textarea:focus ~ .bar:after {
  width: 50%;
}

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@media screen and (max-width: 600px){
#contactTop {
  width: 90%;
}
}
@media (min-width: 600px) {
  .info {
    top: 150px;
  }
  footer {
    top: 150px;
  }
}
@media (min-width: 768px) {
  .info {
    top: 175px;
  }
  footer {
    top: 175px;
  }
}
@media (min-width: 1024px) {
  .info {
    top: 200px;
  }
  footer {
    top: 200px;
  }
}
@media (min-width: 1280px) {
  .info {
    top: 200px;
  }
  footer {
    top: 200px;
  }
}
@media (min-width: 1920px) {
  .info {
    top: 300px;
  }
  footer {
    top: 300px;
  }
}
@media (min-width: 3840px) {
  header {
    bottom: 25px;
  }
  textarea {
    height: 600px;
  }
  .info {
    top: 600px;
  }
  footer {
    top: 600px;
  }
}
@media (min-width: 5120px) {
  .info {
    top: 1000px;
  }
  footer {
    top: 1000px;
  }
}
</style>
