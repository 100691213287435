<template>
  <ul @click="clicked()" class="icon-label" :class="getStyleClasses()">
    <div class="flash ripple" :class="{ active: clickedStatus }"></div>
    <div>
      <vue-icon color="white" size="large" :name="icon"></vue-icon>
    </div>
    <div class="logoTitle">
      <h2 class="font" :class="getStyleClasses()">{{ title }}</h2>
    </div>
  </ul>
</template>

<script scoped>
export default {
  data: () => ({
    isClicked: false,
  }),
  props: {
    title: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    hover: {
      type: String,
      required: false,
    },
    isSVG: {
      type: Boolean,
      require: true,
    },
  },
  mounted() {
    console.log(this.icon, "pop");
  },
  computed: {
    clickedStatus() {
      return this.isClicked;
    },
    route() {
      return this.to;
    },
  },
  methods: {
    toggle() {
      if (this.isClicked === false) {
        this.isClicked = true;
      } else {
        this.isClicked = false;
      }
    },
    clicked() {
      const splitRoute = this.route.split("#");
      this.toggle();
      this.$router.push({ path: splitRoute[0], hash: `#${splitRoute[1]}` });
      setTimeout(() => {
        this.toggle();
      }, 1000);
    },
    getStyleClasses() {
      return [this.size, this.hover].map((val) => val && `is-${val}`);
    },
  },
};
</script>

<style scoped>
.icon-label {
  overflow: hidden;
}
/* default */
.icon {
  width: 1.2em;
  height: 1.2em;
  fill: currentColor;
}
/* sizes */
.icon.is-large {
  width: 2em;
  height: 2em;
}
.icon.is-medium {
  width: 1.6em;
  height: 1.6em;
}
.icon.is-normal {
  width: 1.2em;
  height: 1.2em;
}
.icon.is-small {
  width: 1em;
  height: 1em;
}
.icon.is-tiny {
  width: 0.7em;
  height: 0.7em;
}

.font.is-large {
  font-size: 1.8rem;
}
.font.is-medium {
  font-size: 1.7rem;
}
.font.is-normal {
  font-size: 1.5rem;
}
.font.is-small {
  font-size: 1rem;
}
.font.is-tiny {
  font-size: 1.1rem;
}

.icon-label.is-hover:hover {
  background-color: rgba(65, 65, 65, 1);
}

ul {
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;
}
li {
  margin-bottom: 5px;
}

img {
  display: block;
}
.logo {
  position: relative;
  right: 8px !important;
}

.logoTitle {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.logoTitle > h2 {
  margin: 0;
}
/*ripple Effect */

.flash {
  position: absolute;
  display: inline-block;
  width: 100%;
  border: 0;
  border-radius: 70px;
}
.flash.active {
  height: 62px;
  animation: ripple 0.4s linear;
}

@-webkit-keyframes ripple {
  0% {
    background-color: rgba(65, 65, 65, 1);
    width: 0;
    height: 0;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ripple {
  0% {
    background-color: rgb(83, 82, 82);
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.ripple:before {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: currentColor;
  visibility: hidden;
  z-index: 2;
}
.ripple:not(:active):before {
  -webkit-animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: visibility 0.4s step-end;
  transition: visibility 0.4s step-end;
}
.ripple:active:before {
  visibility: visible;
}
</style>