<template>
  <div v-if="sheet">
    <h3>
      This website uses cookies. You must agree to our 
       <router-link tag="a" to="/privacy">Privacy Policy</router-link>. Please
      feel free to read and agree to our terms.
    </h3>
    <div id="buttonWrap">
      <button @click="setGDPR()">I agree</button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    sheet: true,
    gdpr: false,
  }),
  mounted() {
    if (this.$cookie.getCookie("GDPR_COOKIE_ACCEPTED")) {
      this.sheet = false;
    }
    this.gdpr = true;
  },
  methods: {
    setGDPR() {
      console.log("clicked");
      this.$cookie.setCookie("GDPR_COOKIE_ACCEPTED", "GDPR_COOKIE_ACCEPTED", 1);
      if (this.$cookie.getCookie("GDPR_COOKIE_ACCEPTED")) {
        this.sheet = false;
      }
    },
  },
};
</script>

<style scoped>
div {
  height: 75px;
  background-color: darkslategrey;
  color: white;
  display: flex;
  justify-content: space-between;
}

#buttonWrap {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 100px;
}

h3 {
  top: 0;
  display: flex;
  align-items: center;
}
a {
  color: silver;
}

button:hover {
  cursor: pointer;
}

@media screen and (max-width: 1190px) {
  div {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  h3 {
    text-align: center;

    display: block;
    width: 90%;
    padding: 10px;
  }
  #buttonWrap {
    padding-right: 0;
  }
  button {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  h3,
  button {
    font-size: 14px;
    padding: 10px;
    display: block;
  }
}
</style>