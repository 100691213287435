<template>
  <div
    class="sidebar-backdrop"
    @click="setMenuOpenState()"
    v-if="openMenu"
  ></div>
  <transition name="slide">
    <div v-if="openMenu" class="side-bar">
      <div class="list-items">
        <ul>
          <div>
            <li v-for="(item, i) in items" :key="i">
              <icon-button
                :title="item.title"
                :to="item.to"
                size="small"
                style="height: 64px"
                hover="hover"
              />
            </li>
          </div>
          </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import iconButton from '../../controls/IconButton.vue'
export default {
    components: {
      iconButton
    },
  data: () => ({
    isOpen: false,
    items: [
      {
        icon: "",
        title: "Home",
        to: "/",
        isSVG: false,
      },
      {
        icon: "",
        title: "About",
        to: "/About",
        isSVG: false,
      },
       {
        icon: "",
        title: "Conact",
        to: "/Contact",
        isSVG: false,
      },
    ],
  }),
  created() {
  },
  computed: {
    ...mapGetters(["openMenu"]),
  },
  methods: {
    ...mapActions(["setMenuOpenState"])
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  animation: slide-in ease-in-out 0.8s;
}

.slide-leave-active {
  animation: slide-out ease-out 0.8s;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 60px;
  left: 0;
  cursor: pointer;
  z-index: 999999998;
}

.side-bar {
  position: fixed;
  width: 250px;
  height: 100%;
  top: 0;
  background-color: #363636;
  z-index: 999999999;
}

.logoTitle > h2 {
  color: white;
}
.side-bar-header {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
}

.side-bar-header > li > ul {
  margin-top: 20px;
  margin-bottom: 5px;
}

.side-bar > div > li > div > ul > li.highlight {
  color: lightsteelblue;
}

.list-items {
  position: relative;
  top: 30px;
}

li {
  list-style: none;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .side-bar {
    overflow: scroll;
  }
}
</style>