<template>
<div>
    <agile :dots="false" :autoplay="true" :autoplay-speed="5000" :fade="true" :navButtons="false">
    <div class="slide">
      <img src="../../public/img/icons/IMG1.jpg" oncontextmenu="return false;"/>
    </div>
    <div class="slide">
      <img src="../../public/img/icons/IMG2.jpg" oncontextmenu="return false;"/>
    </div>
    <div class="slide">
      <img src="../../public/img/icons/IMG3.jpg" oncontextmenu="return false;"/>
    </div>
  </agile>
  <figcaption><em>"Get to know your accountant. You want a relationship that develops mutual trust as you are sharing your financial standing with a confidential professional."<br /> <h5>- Nadene Green</h5></em></figcaption>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
div {
  padding: 0 0 0 0;
}

img {
    max-width: 100%;
}
</style>
