<template>
  <section id="privacy">
    <h2>Data Protection Policy</h2>
    <article>
      <p>
        At NG Accounting&copy; we value deeply everyones right to have their data kept
        safe. Our policy explains to you what we do with your information so you
        can make an educated decision on whether or not you wish to accept our
        policy. Please take your time to read our policy carefully.
      </p>
      <h3>Privacy Policy</h3>
      <p>
        Your Privacy is important. We keep all of your information confidential.
        Any emails that are sent by the enquirer to us, whether via email,
        contact form, or newsletter form, will be used to contact the individual
        for conversation between the enquirer and the enquired. If you volunteer
        your email by any of these methods, your email may be used for business
        deals via newsletters. You may opt out at any time if you no longer wish
        to recieve these opportunities. Any forms you submit and information you
        have entered in for any reason shall be kept strictly for transactional
        purposes of the single instance. We will not keep any information from
        one transaction for future transactions.
      </p>
      <h3>Cookies/Similar Tech.</h3>
      <p>
        Cookies are used to make broswers and website functional. This section
        will break down cookies used on our website, what they do, and why they
        are benificial. Cookies may or may not be used to remember your personal
        preferences that you have chosen, if you have visited this website
        before. For example, perhaps you chose to download a particular link. We
        may use cookies to remember that for future reference. We do not use
        cookies for advertising or to gather your information for these
        purposes.
      </p>
        <li>
          <h4>Needed Cookies</h4>
        </li>
        <p>
          You may ask for a particular service or wish to interact with our
          website in a certain manner. For instance, you may wish to submit a
          form. Without using cookies, we are not able to complete what you have
          asked us to do. We keep your information safe and sound.
        </p>
        <li>
          <h4>Working Cookies</h4>
        </li>
        <p>
          These cookies make your web experience enjoyable to your need. They
          remember your choices from previous times to enhance your visit to our
          websites.
        </p>
        <li>
          <h4>Analytics</h4>
        </li>
        <p>
          Cookies used for analytic purposes on our websites help us to make
          your web experience more enjoyable. From these we can find our
          audience, what they like or don't like by how long they visit a page,
          how many visitors vist the website, when they visit, where their
          country of orgin is, and what type of platform they prefer. Cookies
          help developers build better websites for their visitors.
        </p>
      <h3>Your IP Address</h3>
      <p>
        IP addresses are
        <i>like</i> id tags of a computer on the interenet, <B>BUT</B> they do
        not identify you as an individual without additional information (e.g.
        your ISP has additional information).
      </p>
      <h3>Web Beacons</h3>
      <p>
        Beacons are web files that basically record activity. They keep track of
        when pages are visited. They track usage and performace. Our websites
        may contain beacons.
      </p>
      <h2>
        It is up to
        <i>YOU</i>
      </h2>
      <p>
        By usising our website, you agree to the use of cookies and you agree to
        our policies. If you don't agree, it's okay, no hard feelings. Depending
        on your browser, you can control many of these cookie options and
        similar technologies and be able to adjust these settings. When you
        first entered our site, cookies were sent to the browser and stored on
        your computer. In your browser, you can clear these as well. Visiting
        websites without cookies may dramatically effect your browsing
        experience.
      </p>
      <h2>Copyright</h2>
      <p>
        All work that has been developed for NG Accounting&copy; by Cartisim&copy; is
        owned exclusively by NG Accounting.
      </p>
       <p>
        All photographs by Caili Hartman are copyrighted by Caili Hartmen Photography&copy; 2019/2020. Non-exclusive rights belong only to NG-Accounting&copy;
      </p>
    </article>
  </section>
</template>

<script>
export default {};
</script>

<style>
#privacy {
  position: relative;
  margin: 0 auto;
  width: 60%;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  #privacy {
  width: 100%;
}
}
</style>
