<template>
  <div>
      <h3>{{ $filters.formatDay(currentDate) }}</h3>
       <h1>{{ $filters.formatDate(currentDate) }}</h1>
        <p>{{ $filters.formatMonth(currentDate) }}</p>
  </div>
</template>

<script>
export default {
data: () => ({
    date: Date
}),
method: {
},
computed: {
    currentDate () {
        return new Date()
    }
}
}
</script>

<style scoped>
div {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 8px;
    padding: 20px;
      box-shadow: 0 4px 4px 0 rgba(50,50,50,.4);
    -webkit-box-shadow: 0 4px 4px 0 rgba(50,50,50,.4);
    -moz-box-shadow:0 4px 4px 0 rgba(50,50,50,.4);
}
h3, h1, p {
    top: 0;
    margin:0;
    padding-bottom: 10px;
}
</style>