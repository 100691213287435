<template>
  <div id="app">
    <header-view></header-view>
    <sidebar />
    <div class="some-class">
      <date />
    </div>
    <router-view />
    <footer-view></footer-view>
   <GDPR />
  </div>
</template>

<script>
import Header from './components/navigation/Header.vue'
import Footer from './components/navigation/Footer.vue'
import Date from './controls/Date.vue'
import Sidebar from './components/navigation/Sidebar.vue'
import GDPR from './components/GDPR.vue'
export default {
  components: {
    headerView: Header,
    footerView: Footer,
    Date,
    Sidebar,
   GDPR
  } 
}
</script>

<style lang="css">
/*NG Accounting CSS*/

html {
  margin: 0 auto;
  padding: 0;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

*:not(input, textarea) {
    -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
img {
  pointer-events: none;
}
/*Phone View*/
@media (min-width: 320px) {
  body {
    position: relative;
    margin: 0 auto;
  }
  img {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }
  em {
    color: purple;
  }
  b {
    color: #000;
  }
  h4 {
    position: relative;
    margin: 0 auto;
    text-align: center;
  }
  h5 {
    position: relative;
    top: 10px;
    color: darkslategrey;
  }

  #sub {
    color: darkslategrey;
  }
  #sub li {
    list-style-type: circle !important;
  }
  #icon {
    position: relative;
    width: 175px;
    margin: 0 auto;
    box-shadow: 3px 3px 4px darkslategrey;
    -webkit-box-shadow: 3px 3px 4px darkslategrey;
    -moz-box-shadow: 3px 3px 4px darkslategrey;
  }

  /* Calander */
  .some-class {
    display: none;
  }

  #aboutBack {
    position: relative;
    margin: 0 auto;
    background-image: url(../public/img/icons/back.jpg);
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    padding: 10px 40px 80px 40px;
  }
  #aboutBack article {
    background-color: #fff;
    width: 80%;
  }
  #menu2 {
    display: none;
  }
  header {
    position: relative;
    width: 100%;
    height: 60px;
    margin: 0 auto;
    background: linear-gradient(#4d004d, #fff);
  }

  /* header */
  #toggle {
    position: relative;
    color: #f2f2f2;
    float: right;
    right: 10%;
    top: 20px;
  }
  #menu {
    position: relative;
    display: none;
    z-index: 999;
  }
  #menu ul {
    position: relative;
    display: block;
    background: linear-gradient(0.25turn, #4d004d, #fff);
    width: 100px;
    padding-bottom: 75px;
    border-radius: 3px;
  }
  #menu li {
    position: relative;
    top: 75px;
    padding-bottom: 100px;
    list-style-type: none;
  }
  #menu a {
    text-decoration: none;
    color: #f2f2f2;
  }
  #close {
    position: relative;
    top: 15px;
    right: 15%;
    color: darkslategrey;
    float: right;
  }

  #services {
    position: relative;
    /* top: 125px; */
    padding: 20px;
    background-color: #f2f2f2;
  }
  #services li {
    list-style-type: square;
  }
  article {
    position: relative;
    padding: 20px;
    margin: 0 auto;
  }
  p {
    text-indent: 10px;
  }
  a {
    text-decoration: none;
    color: navy;
  }
  a:hover {
    color: lightslategrey;
  }
  h1,
  h2,
  figcaption,
  h5 {
    position: relative;
    text-align: center;
    margin: 0 auto;
  }

  figcaption {
    padding: 50px 0 50px 0;
  }
  .info {
    position: relative;
    margin: 0 auto;
    /* top: 130px; */
    display: block;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
    clear: left;
    background: linear-gradient(#4d004d, #fff);
  }
  #infoHeading li {
    position: relative;
    padding-bottom: 10px;
    list-style-type: none;
    font-size: 20px;
  }
  .info ul {
    position: relative;
    right: 7%;
  }
  .info li {
    list-style-type: none;
  }
  .info a {
    text-decoration: none;
  }
  .info h3 {
    color: lightgrey;
  }
  footer {
    position: relative;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    height: 60px;
    background: linear-gradient(#fff, #4d004d);
  }
  footer h4 {
    position: relative;
    margin: 0 auto;
    text-align: center;
    top: 20px;
  }
  /* Contact Form */
  #contact {
    position: relative;
    margin: 0 auto;
    width: 90%;
  }
  form {
    position: relative;
    width: 100%;
    margin: 0 auto;
    top: 50px;
  }
  input {
    position: relative;
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 30px;
    border-radius: 2px;
  }
  label {
    position: relative;
    margin: 0 auto;
  }
  textarea {
    position: relative;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 100%;
    height: 200px;
    border-radius: 2px;
    border: 2px solid lightgrey;
    border-style: inset;
  }
  button {
    position: relative;
    color: #fff;
    font-size: 18px;
    margin: 0 auto;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 2px solid silver;
    background-color: #4d004d;
    box-shadow: 3px 4px 5px #000;
  }
  button:hover {
    background-color: purple;
    transition: 0.5s;
  }
  /* Nexus 7 */
  @media (min-width: 600px) {
    figcaption h5 {
      position: relative;
      margin-left: 60%;
    }
    #back {
      position: relative;
      margin: 0 auto;
      padding: 60px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .info ul {
      right: 3%;
    }

    /* Form */
    #contact h1 {
      position: relative;
    }
    form {
      width: 100%;
    }
    input {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 30px;
      border-radius: 2px;
      margin-bottom: 20px;
    }

    textarea {
      width: 100%;
      border-radius: 2px;
      border: 2px solid lightgrey;
      border-style: inset;
    }
  }
  /* iPad */
  @media (min-width: 768px) {
    #toggle {
      display: none !important;
    }
    /* Calander */
    .some-class {
      position: fixed;
      display: block;
      z-index: 99999;
      width: 175px;
    }
    #menu2 {
      display: flex;
      justify-content: flex-end;
      padding-right: 40px;
    }
    #menu2 ul {
      position: relative;
      margin-left: 50%;
      top: 20px;
    }
    #menu2 > ul > a {
      position: relative;
      display: inline;
      padding-left: 40px;
      color: navy;
      font-size: 20px;
    }
    #menu2 > ul > a:hover {
      cursor: pointer;
      color: silver;
    }
    
    #services {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
      margin: 0 auto;
    }
    #services article {
      position: relative;
      width: 90%;
    }
    .col3 {
      float: left !important;
      width: 33%;
    }
    .info {
      height: 150px;
      right: 0;
    }
    .col2 {
      position: relative;
      width: 43%;
      padding: 20px;
      float: left !important;
    }
    h5 {
      clear: left;
    }
    h4 {
      clear: left;
    }
    h3 {
      position: relative;
      margin: 0 auto;
      text-align: center;
      clear: left;
      top: 20px;
    }
    footer {
      clear: left;
    }
    /* form */
    form {
      width: 80%;
    }
  }

  /*Wide Pad */
  @media (min-width: 1024px) {
    #menu2 ul {
      margin-left: 60%;
    }
    #back article {
      font-size: 18px;
    }

    /* Contact Form */
    textarea {
      height: 300px;
    }
  }
  /* Desktop */
  @media (min-width: 1280px) {
    #about p {
      position: relative;
      margin: 0 auto;
      width: 80%;
      font-size: 18px;
      padding: 10px;
    }

    .col2 {
      width: 46%;
    }
    /* Contact Form */
    #contact {
      position: relative;
      margin: 0 auto;
      width: 50%;
    }
    form {
      width: 90%;
    }
  }

  /* Wide Desktop */
  @media (min-width: 1400px) {
    #back article {
      width: 75%;
    }
  }

  /* MBP */
  @media (min-width: 1680px) {
    .col3 {
      width: 33%;
    }
  }

  /* 4K */
  @media (min-width: 3840px) {
    #menu2 {
      margin-left: 60%;
      font-size: 24px;
    }
    figcaption {
      font-size: 20px;
      width: 50%;
    }

    #back h1 {
      font-size: 36px;
    }
    #back article {
      width: 50%;
      font-size: 24px;
    }
    #about p {
      width: 75%;
      font-size: 22px;
    }
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 30px;
    }
    #services article {
      width: 50%;
    }
    #services li {
      font-size: 24px;
    }

    #half {
      position: relative;
      margin: 0 auto;
      width: 50%;
    }
  }
  /* 5k */
  @media (min-width: 5120px) {
    body {
      width: 75%;
    }
  }
}

@media screen and (min-width: 769px) {
  header {
    display: flex;
    justify-content: space-between;
  }
  #hamNav {
    display: none;
  }

  #iconWapper {
    position: relative;
    width: 60%;
    height: 40px;
  }

  #menu2 {
    position: relative;
    width: 40%;
    right: 10px;
  }

  #menu2 > div {
    left: 0;
    top: 10px;
  }
}

@media screen and (max-width: 768px) {
  header {
    display: flex;
    justify-content: space-between;
  }
  #iconWapper {
    position: relative;
    width: 90%;
    height: 40px;
  }

  #icon {
    width: 100px;
    top: 10px;
    left: 10px;
  }
  #hamNav {
    position: relative;
    width: 10%;
    right: 10px;
  }

  #hamNav div {
    left: 0;
    top: 10px;
  }
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltiptext > a {
  color: lightsteelblue;
}

.tooltiptext > a:hover {
  color: white;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>

