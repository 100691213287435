<template>
  <div>
    <slider />
    <section id="back-wrapper">
      <h1>Nadene Lewis-Green</h1>
      <h5>
        Bachelor of Arts in Business Administration
        <br />Eastern Washington University
      </h5>
    </section>
    <section id="about">
      <section id="aboutBack">
        <article>
          <div id="back">
            <div id="topBack">
              <div class="tooltip" id="halfTip">
                <span class="tooltiptext"
                  >This image is copyrighted by
                  <router-link tag="a" to="/privacy"
                    >Caili Hartmen Photography&copy;</router-link
                  ></span
                >
                <img
                  src="../../public/img/nadeneGreen2.jpg"
                  alt="Nadene Green"
                />
              </div>
              <article>
                <p>
                  During high school, my class was given as assessment regarding
                  career choices to fit our personality. My two choices were
                  either a librarian or an accountant. I was taking an
                  accounting course at the time and knew this what I wanted to
                  do. As my first accounting instructor stated, “Either you love
                  accounting or you hate it. And there is no in-between.” We
                  lost half the class by the second quarter. And if you haven’t
                  guessed yet. I loved it!
                </p>
              </article>
            </div>
          </div>

          <div id="back">
            <div id="middleBack">
              <article>
                <p>
                  <i>
                    I love spending time with my husband Neil and our two furry
                    kids Claire Bear and Panda Butters. The girls love to visit
                    my client’s offices whether they have to wait in the car or
                    get to go in. And they are always ready to greet guests at
                    our office.
                  </i>
                </p>
              </article>
            </div>
            <div class="image-wrapper tooltip">
              <span class="tooltiptext"
                >This image is copyrighted by
                <router-link tag="a" to="/privacy"
                  >Caili Hartmen Photography&copy;</router-link
                ></span
              >
              <img src="../../public/img/nadeneGreen3.jpg" alt="Nadene Green" />
            </div>
          </div>
          <div id="bottomText">
            <p>
              For over 20 years, I have been working in the accounting industry.
              I started a small business in 2007 preparing tax returns and
              accounting services while working a full time job. Then in October
              of 2015, I decided to take my small firm to the next level. It has
              been an amazing and fulfilling journey. I enjoy helping my clients
              succeed in their businesses.
            </p>
          </div>
        </article>
      </section>
    </section>
  </div>
</template>

<script>
import Slider from "../components/Slider.vue";
export default {
  // metaInfo: () => ({
  //   title: 'About NG Accounting',
  //   titleTemplate: '%s |  For over 20 years, I have been working in the accounting industry. I started a small business in 2007 preparing tax returns and accounting services while working a full time job. Then in October of 2015, I decided to take my small firm to the next level. It has been an amazing and fulfilling journey. I enjoy helping my clients succeed in their businesses.',
  //   htmlAttrs: {
  //     lang: 'en',
  //     amp: undefined
  //   },
  //   headAttrs: {
  //     test: true
  //   },
  //   bodyAttrs: {
  //     tabIndex: 0
  //   },
  //   meta: [
  //     { name: 'description', content: 'About NG Accounting, Nadene Green', vmid: 'about' }
  //   ],
  // }),
  components: {
    Slider,
  },
};
</script>

<style scoped>
#back {
  opacity: 1;
}

#back > div {
  display: flex;
  align-items: center;
}

#halfTip {
  width: 50%;
}

#topBack > article {
  font-weight: 600;
  line-height: 2.2rem;
}
#topBack > article > p {
  font-size: 1.2rem !important;
}
#middleBack {
  color: darkslategrey;
}

#bottomText > p {
  font-weight: 600;
}

@media screen and (max-width: 940px){
  #back:first-child {
    padding-bottom: 0;
  }
    #back:nth-child(2) {
    padding-top: 0;
  }
  #back, #topBack {
    display: block !important;
  }
  #halfTip {
  width: 100%;
}
}

@media screen and (max-width: 600px){
#aboutBack {
  width: 100%;
  padding: 0;
}
#aboutBack > article{
    padding: 0;
    margin: 0;
    width: 100%;
}

#bottomText > p{
  padding: 20px;
}
}
</style>
